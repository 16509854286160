<template>
<!--  <div style="width: 100%; height: calc(100vh - 200px); background: #6e809a; display: flex;">-->
  <div>
    <el-row :gutter="12">
      <el-col :span="12">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span class="cardTitle">导入数据</span>
            <el-button style="float: right; padding: 3px 0" type="text">?</el-button>
          </div>
          <el-upload :action="$baseUrl + '/assets/upload'" :show-file-list="false" :headers="{ token: user.token }"
                     :before-upload="handleBeforeUpload" :on-success="handleFileUploadSuccess" :data="{department: user.department}">
            <div class="buttonDiv">导入固定资产明细表</div>
          </el-upload>
          <el-upload :action="$baseUrl + '/assetscheck/upload'" :show-file-list="false" :headers="{ token: user.token }"
                     :before-upload="handleBeforeUpload" :on-success="handleFileUploadSuccess" :data="{department: user.department}">
            <div class="buttonDiv">导入资产盘点报表</div>
          </el-upload>

        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header" class="clearfix">
            <span class="cardTitle">导出数据</span>
            <el-button style="float: right; padding: 3px 0" type="text">?</el-button>
          </div>


          <div class="buttonDiv">
            <a :href="$baseUrl + '/assetscheck/excel'">导出资产盘点报表</a>
            <!--          <a href="http://localhost:9090/assetscheck/export">123</a>-->
          </div>



        </el-card>
      </el-col>

    </el-row>

  </div>
</template>

<script>
export default {
  name: "assetstable",
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user') || '{}'),
    }
  },
  methods:{
    handleFileUploadSuccess(res) {
      console.log(res)
      this.$msgbox.close()
      console.log("完成！")
      this.$msgbox(res.data, '提示', {
        showCancelButton: false,
        showConfirmButton: false,
        type: 'info'
      });
      // this.$message.success("上传成功")
      // this.load()

    },
    handleBeforeUpload(){
      this.$msgbox('正在上传，请稍等.....', '提示', {
        showCancelButton: false,
        showConfirmButton: false,
        type: 'info'
      });
     console.log("上传！")
    },
  }
}
</script>

<style scoped>
.buttonDiv{
  width: calc(50vw - 260px);
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
text-align: center;
  background: whitesmoke;
}
.buttonDiv:hover{
  font-weight: 600;
  background: #b3d1eb;
  cursor: pointer;
}
a{
  text-decoration: none;
}
.cardTitle{
  color: #409EFF;
  font-size: 16px;
  font-weight: 900;
}
</style>